.Calendar {
  margin: 4rem 4rem;
}

.CalendarTitle {
  font-size: 1.25rem;
  color: #292f33;
}
.CalendarTitle span {
  font-weight: 700;
}

.Main {
  display: flex;
  margin-top: 2rem;
}

.CardsList {
  display: flex;
  justify-content: start;
  flex-wrap: wrap;
}
.Modal {
  min-width: 21rem;
  max-width: 21rem;
}

/* large tablets & laptops */
@media screen and (min-width: 1200px) {
  .Calendar {
    margin: 4rem 11rem;
  }
}

/* Very large screens */
@media screen and (min-width: 1500px) {
}
