@import url(//netdna.bootstrapcdn.com/font-awesome/3.2.1/css/font-awesome.css);
* {
  margin: 0;
  padding: 0;
  scroll-behavior: smooth;
}

body {
  font-family: "PT Sans", sans-serif;
  background: #f3f2f0;
}

@font-face {
  font-family: "Futura Extra Bold";
  src: local("Futura Extra Bold"),
    url(/static/media/Futura_Extra_Bold.db7c22c7.otf) format("truetype");
}

.control-panel {
  font-size: 16px;
  color: black;
  background-color: white;
  border-radius: 5px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.clickable {
  cursor: pointer;
}

.disabled {
  cursor: not-allowed;
  color: lightgray;
}

/* .App {
  font-family: Arial, Helvetica, sans-serif;
  font-weight: bold;
  font-size: 3rem;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
} */

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.Login_Wrapper__bsfgs {
  height: 100vh;
  background-color: #e5e5e5;
  overflow: hidden;
  overflow-y: auto;
}
.Login_LoginContainer__BfB9t {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  min-height: 100%;
  padding: 20px;
}

.Login_Login__QGtuR {
  background-color: white;
  border-radius: 10px;
  padding: 30px;
  width: 90%;
  max-width: 450px;
  position: relative;
  box-shadow: 0 30px 60px 0 rgba(0, 0, 0, 0.3);
}

.Login_ImgWrapper__22PXs {
  width: 80%;
  margin: 0 auto;
  padding: 20px;
}
.Login_ImgWrapper__22PXs img {
  width: 100%;
}

.Login_LoginTitle__23q_c {
  font-size: 2em;
  text-align: left;
  line-height: 1.2em;
  margin-bottom: 40px;
}
.Login_LoginTitle__23q_c span {
  background: -webkit-gradient(
    linear,
    left top, left bottom,
    color-stop(60%, rgba(255, 255, 255, 0)),
    color-stop(40%, rgba(0, 78, 124, 0.178))
  );
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0) 60%,
    rgba(0, 78, 124, 0.178) 40%
  );
}

/* ====FORM STYLING START====== */
.Login_InputGroup__1EHeb {
  position: relative;
  width: 80%;
  margin-bottom: 40px;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
}
.Login_Input__1m1cr {
  font-size: 18px;
  font-family: inherit;
  padding: 10px 10px 10px 5px;
  display: block;
  width: 100%;
  border: none;
  border-bottom: 1px solid #757575;
  font-family: Arial, Helvetica, sans-serif;
}
.Login_Input__1m1cr:focus {
  outline: none;
}
.Login_Phone__2h6r4 {
  padding-left: 45px;
}
.Login_InputGroup__1EHeb i {
  position: absolute;
  font-size: 1.3em;
  top: 12px;
  left: -35px;
  color: #999;
  cursor: pointer;
}
.Login_MUIInputGroup__3qBQc {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 2em;
}
.Login_InputError__3nZkL {
  font-size: 0.85rem;
  color: #e0004d;
  margin-top: 0.5em;
}
/* LABEL ======= */
.Login_InputLabel__2e17V {
  color: #999;
  font-size: 18px;
  font-weight: normal;
  position: absolute;
  pointer-events: none;
  left: 5px;
  top: 10px;
  transition: 0.2s ease all;
  -moz-transition: 0.2s ease all;
  -webkit-transition: 0.2s ease all;
}
/* active state */
.Login_Input__1m1cr:focus ~ .Login_InputLabel__2e17V,
.Login_Input__1m1cr:valid ~ .Login_InputLabel__2e17V {
  top: -20px;
  font-size: 14px;
  color: #003c71;
}
/* BOTTOM BARS ========= */
.Login_Bar__3X9Ow {
  position: relative;
  display: block;
  width: 100%;
}
.Login_Bar__3X9Ow:before,
.Login_Bar__3X9Ow:after {
  content: "";
  height: 2px;
  width: 0px;
  bottom: 1px;
  position: absolute;
  background: #003c71;
  transition: 0.2s ease all;
  -moz-transition: 0.2s ease all;
  -webkit-transition: 0.2s ease all;
}
.Login_Bar__3X9Ow:before {
  left: 50%;
}
.Login_Bar__3X9Ow:after {
  right: 50%;
}
/* active state */
.Login_Input__1m1cr:focus ~ .Login_Bar__3X9Ow:before,
.Login_Input__1m1cr:focus ~ .Login_Bar__3X9Ow:after {
  width: 50%;
}

/* HIGHLIGHTER ============ */
.Login_Highlight__3tWHV {
  position: absolute;
  height: 60%;
  width: 100px;
  top: 25%;
  left: 0;
  pointer-events: none;
  opacity: 0.5;
}
/* active state */
.Login_Input__1m1cr:focus ~ .Login_Highlight__3tWHV {
  animation: Login_inputHighlighter__DEca_ 0.3s ease;
  -webkit-animation: Login_inputHighlighter__DEca_ 0.3s ease;
  -moz-animation: Login_inputHighlighter__DEca_ 0.3s ease;
}
/* Animations */
@keyframes Login_inputHighlighter__DEca_ {
  from {
    background: #003c71;
  }
  to {
    width: 0;
    background: transparent;
  }
}
@-webkit-keyframes Login_inputHighlighter__DEca_ {
  from {
    background: #003c71;
  }
  to {
    width: 0;
    background: transparent;
  }
}

/* Indicatif ====== */
.Login_Indicatif__gv_UU {
  position: absolute;
  font-size: 18px;
  top: 24%;
  left: 0;
  opacity: 0;
  font-family: Arial, Helvetica, sans-serif;
}
/* active state */
.Login_Input__1m1cr:focus ~ .Login_Indicatif__gv_UU,
.Login_Input__1m1cr:valid ~ .Login_Indicatif__gv_UU {
  opacity: 1;
}

/* ========= CGU CHECKBOX =========== */

.Login_CGUGroup__mJk7C input[type="checkbox"] {
  display: none;
}
.Login_CGUGroup__mJk7C input[type="checkbox"] + label:before {
  font-family: FontAwesome;
  display: inline-block;
}

.Login_CGUGroup__mJk7C input[type="checkbox"]:checked + label:before {
  content: "\f046";
}
.Login_CGUGroup__mJk7C input[type="checkbox"]:checked + label:before {
  letter-spacing: 8px;
}

.Login_CGUGroup__mJk7C input[type="checkbox"] + label:before {
  content: "\f096";
}
.Login_CGUGroup__mJk7C input[type="checkbox"] + label:before {
  letter-spacing: 10px;
}

/* CGU Link ====== */
.Login_CGUGroup__mJk7C label {
  color: #828489;
  font-size: 0.9em;
}
.Login_CGUGroup__mJk7C span {
  cursor: pointer;
  /* font-weight: 700; */
  background: -webkit-gradient(
    linear,
    left top, left bottom,
    color-stop(60%, rgba(255, 255, 255, 0)),
    color-stop(40%, rgba(0, 78, 124, 0.178))
  );
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0) 60%,
    rgba(0, 78, 124, 0.178) 40%
  );
}

/* ======SUBMIT BUTTON ====== */
.Login_SubmitButtonContainer__2I1mK {
  margin-top: 40px;
}

/* Anon link */
.Login_AnonLogin__1fbKb {
  margin-top: 1em;
  text-align: center;
}

.RefInfo_RefInfoTitle__hOxC1 {
  display: flex;
}
.RefInfo_RefInfoTitle__hOxC1 h3 {
  margin-left: 8px;
}

.RefInfo_RefWrapper__3f2tq img {
  width: 100%;
  margin-top: 15px;
}

.CGU_CGUTitle__2TxL8 {
  text-align: center;
}

.CGU_CGUWrapper__1cT1j {
  margin-top: 20px;
  height: 50vh;
  overflow-y: scroll;
}

.CGU_Article__3w1cd {
  margin-bottom: 1em;
}

.CGU_ArticleTitle__jtTos {
  margin-bottom: 0.5em;
}
.CGU_ArticleSubtitle__TEJNk {
  margin-bottom: 0.5em;
}
.CGU_ArticleContent__3Rt4P {
  margin-bottom: 0.5em;
}
.CGU_ArticleContent__3Rt4P p {
  margin-bottom: 0.3em;
}

.Modal_Modal__2Nsbr {
  position: fixed;
  z-index: 500;
  background: white;
  border-radius: 9px;
  width: 90%;
  padding: 16px;
  left: 5%;
  top: 30%;
  box-sizing: border-box;
  -webkit-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}

@media (min-width: 600px) {
  .Modal_Modal__2Nsbr {
    width: 500px;
    left: calc(50% - 250px);
  }
}

.Backdrop_Backdrop__2FVVo {
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 100;
  left: 0;
  top: 0;
  /* background-color: rgba(229, 229, 229, 0.8); */
  background-color: rgba(0, 0, 0, 0.5);
}

.SubmitButton_SubmitButton__3I_5n {
  display: flex;
  margin: 0 auto;
  align-items: center;
  padding: 1em 1em;
  min-width: 10em;
  border: 0;
  outline: 0;
  font-size: 0.9rem;
  text-align: center;
  /* Primaire Rouge 1 */
  background: #e0004d;
  /* 6dp elevation */
  box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.2), 0px 1px 18px rgba(0, 0, 0, 0.12),
    0px 6px 10px rgba(0, 0, 0, 0.14);
  border-radius: 7px;
  cursor: pointer;
  color: white;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  position: relative;
}

.SubmitButton_SubmitButton__3I_5n:active {
  box-shadow: inset 1px 1px 2px #babecc, inset -1px -1px 2px #fff;
}

.SubmitButton_SubmitButton__3I_5n:disabled {
  background-color: gray;
  color: #ccc;
  cursor: not-allowed;
}

/* Invalid state */
.SubmitButton_Invalid__164w- i {
  -webkit-transform: translate(-30vw, 11vh);
          transform: translate(-30vw, 11vh);
  -webkit-transition: all linear 0.2s;
  transition: all linear 0.2s;
  opacity: 0;
}
.SubmitButton_Invalid__164w- span {
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  /* transition: all linear 0.2s; */
}

/* Valid state */
.SubmitButton_Valid__3TAqS i {
  color: #ffc300;
  font-size: 1.4em;
  margin-left: 0.4em;
  -webkit-transition: all linear 0.2s;
  transition: all linear 0.2s;
  opacity: 1;
}

/* Loading state */
.SubmitButton_Clicked__2gEO6 i {
  -webkit-transform: translate(100vw, -90vh);
          transform: translate(100vw, -90vh);
  -webkit-transition: all linear 1.3s;
  transition: all linear 1.3s;
}

.CircularLoading_CircularLoading__1Unqo {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.Home_SubNavbar__FT1s_ {
  margin: 0 1.5rem;
  display: flex;
  justify-content: space-between;
}
.Home_SubNavbar__FT1s_ p {
  padding: 0.625rem 1.25rem;
  border-radius: 30px;
  font-weight: 700;
  font-size: 0.875rem;
}
.Home_ActionButton__1Mdon p {
  color: white;
  background: #3b4578;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.4);
  border-radius: 30px;
  cursor: pointer;
  position: relative;
}
.Home_ActionButtonSvg__nFjCg {
  display: none;
}
/* large tablets & laptops */
@media screen and (min-width: 600px) {
}

/* large tablets & laptops */
@media screen and (min-width: 768px) {
  .Home_SubNavbar__FT1s_ {
    margin: 0 4rem;
  }
  .Home_ActionButtonSvg__nFjCg {
    display: block;
    width: 15rem;
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
    position: absolute;
    top: -35rem;
    right: 10rem;
    right: 3rem;
  }
  .Home_Footer__2zVY1 {
    width: 100%;
    margin-top: 6rem;
    padding-right: 5rem;
    display: flex;
    justify-content: flex-end;
  }
}

/* large tablets & laptops */
@media screen and (min-width: 1200px) {
  .Home_SubNavbar__FT1s_ {
    margin: 0 11rem;
  }
  .Home_ActionButtonSvg__nFjCg {
    right: 10rem;
  }
}

/* Very large screens */
@media screen and (min-width: 1500px) {
}

.HomeToolbar_Header__3o6zS {
  background-color: #f3f2f0;
  padding: 2rem 1.5rem;
}

.HomeToolbar_Toolbar__3_NMC {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.HomeToolbar_Title__2g9qi {
  margin-top: 1.5rem;
  font-family: "Nunito Sans";
  line-height: 1.7rem;
}
.HomeToolbar_Title__2g9qi h3 {
  font-size: 1.8rem;
  color: #292f33;
  text-transform: uppercase;
  font-weight: 900;
}
.HomeToolbar_Title__2g9qi h4 {
  font-size: 1.6rem;
  font-weight: 600;
  color: #5b5855;
}

.HomeToolbar_Description__3Fup7 {
  color: #5b5855;
  margin-top: 2rem;
  font-weight: 400;
  font-size: 1.125rem;
}

/* large tablets & laptops */
@media screen and (min-width: 768px) {
  .HomeToolbar_Header__3o6zS {
    padding: 2rem 4rem;
  }
  .HomeToolbar_Title__2g9qi h3 {
    font-size: 1.8rem;
  }
  .HomeToolbar_Title__2g9qi {
    display: flex;
  }
  .HomeToolbar_Title__2g9qi h4 {
    margin-left: 1rem;
  }
  .HomeToolbar_Description__3Fup7 {
    width: 23rem;
  }
}

/* large tablets & laptops */
@media screen and (min-width: 1200px) {
  .HomeToolbar_Header__3o6zS {
    padding: 2rem 11rem;
  }

  .HomeToolbar_Title__2g9qi h3 {
    font-size: 2rem;
  }
}

.HomeFooter_Footer__3wQO4 {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.HomeFooter_LogoContainer__1AC31 {
  width: 15em;
  margin: 1em 0;
}
.HomeFooter_LogoContainer__1AC31 img {
  width: 100%;
}
.HomeFooter_LegalLinks__1k8eu {
  display: flex;
  margin-top: 1em;
  margin-bottom: 3rem;
  flex-wrap: wrap;
  justify-content: center;
  padding: 0.5rem;
}
.HomeFooter_LegalLinks__1k8eu p {
  cursor: pointer;
  margin: 0.5rem;
  font-size: 0.8rem;
  color: #828282;
  padding: 0.8rem 1rem;
  background-color: #f3f2f0;
  border-radius: 10px;
}
.HomeFooter_Copyrights__DAy2W {
  margin: 1em 0;
  color: #5b5855;
  text-align: center;
  font-size: 0.8rem;
}
.HomeFooter_Copyrights__DAy2W span {
  text-transform: uppercase;
  font-size: 1rem;
  font-weight: 900;
  font-family: "Nunito Sans", sans-serif;
}

.HomeFooter_ImmobiliereLogo__1GKVe {
  width: 12rem;
  margin-bottom: 1rem;
  margin-left: auto;
  margin-right: auto;
}

.PDC_CGUTitle__1nuP6 {
  text-align: center;
}

.PDC_CGUWrapper__24V7K {
  margin-top: 20px;
  height: 50vh;
  overflow-y: scroll;
}

.PDC_Article__3dqn- {
  margin-bottom: 1em;
}

.PDC_ArticleTitle__1uUAf {
  margin-bottom: 0.5em;
}
.PDC_ArticleSubtitle__3zchS {
  margin-bottom: 0.5em;
}
.PDC_ArticleContent__2h2LW {
  margin-bottom: 0.5em;
}
.PDC_ArticleContent__2h2LW p {
  margin-bottom: 0.3em;
}

.ViewSelector_ViewSelector__1BLlM {
  display: flex;
  justify-content: space-between;
}
.ViewSelector_ViewSelector__1BLlM p {
  border-radius: 30px;
  cursor: pointer;
  box-sizing: border-box;
}
.ViewSelector_UnselectedView__hStJy {
  color: #292f33;
  background: #f3f2f0;
  border: 1px solid #3b4578;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.4);
}
.ViewSelector_SelectedView__1cOSf {
  background: #3b4578;
  color: white;
  border: 1px solid #3b4578;
}
/* large tablets & laptops */
@media screen and (min-width: 600px) {
}

/* large tablets & laptops */
@media screen and (min-width: 768px) {
  .ViewSelector_ViewSelector__1BLlM {
    width: 15rem;
  }
}

/* large tablets & laptops */
@media screen and (min-width: 1200px) {
}

/* Very large screens */
@media screen and (min-width: 1500px) {
}

/* large tablets & laptops */
@media screen and (min-width: 600px) {
}

/* large tablets & laptops */
@media screen and (min-width: 768px) {
}

/* large tablets & laptops */
@media screen and (min-width: 1200px) {
}

/* Very large screens */
@media screen and (min-width: 1500px) {
}

.CalendarDesktop_Calendar__1Kuy7 {
  margin: 4rem 4rem;
}

.CalendarDesktop_CalendarTitle__QE_Dl {
  font-size: 1.25rem;
  color: #292f33;
}
.CalendarDesktop_CalendarTitle__QE_Dl span {
  font-weight: 700;
}

.CalendarDesktop_Main__Sv-8Q {
  display: flex;
  margin-top: 2rem;
}

.CalendarDesktop_CardsList__l5vWs {
  display: flex;
  justify-content: start;
  flex-wrap: wrap;
}
.CalendarDesktop_Modal__bdyDd {
  min-width: 21rem;
  max-width: 21rem;
}

/* large tablets & laptops */
@media screen and (min-width: 1200px) {
  .CalendarDesktop_Calendar__1Kuy7 {
    margin: 4rem 11rem;
  }
}

/* Very large screens */
@media screen and (min-width: 1500px) {
}

.CalendarCard_Card___1awa {
  max-width: 15rem;
  min-width: 15rem;
  max-height: 10rem;
  padding: 1rem;
  margin-bottom: 2rem;
  margin-right: 2rem;
  background: #ffffff;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25), 0px 4px 4px rgba(0, 0, 0, 0.1);
  border-radius: 18px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
}
.CalendarCard_Emoji__z1jrs {
  font-size: 2rem;
  margin: auto;
}
.CalendarCard_Card___1awa p {
  margin-top: 0.5rem;
  font-size: 1rem;
}
.CalendarCard_SelectedCard__3EfX- {
  background: #3b4578;
  border-radius: 18px;
  color: white;
  max-width: 15rem;
  max-height: 10rem;
  padding: 1rem;
  margin-bottom: 2rem;
  margin-right: 2rem;
  cursor: pointer;
  display: flex;
  flex-direction: column;
}
.CalendarCard_SelectedCard__3EfX- p {
  margin-top: 0.5rem;
  font-size: 1rem;
}

/* large tablets & laptops */
@media screen and (min-width: 600px) {
}

/* large tablets & laptops */
@media screen and (min-width: 768px) {
}

/* large tablets & laptops */
@media screen and (min-width: 1200px) {
}

/* Very large screens */
@media screen and (min-width: 1500px) {
}

.CalendarModal_CalendarModal__3u7aB {
  background: #ffffff;
  border-radius: 18px;
  height: 30rem;
  overflow-y: scroll;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.CalendarModal_CalendarModal__3u7aB::-webkit-scrollbar {
  display: none;
}

.CalendarModal_StepTitle__350BE {
  background-color: #3b4578;
  color: white;
  border-radius: 18px 18px 0 0;
  display: flex;
  padding: 1rem;
  font-size: 1.18rem;
  justify-content: center;
  align-items: center;
  position: -webkit-sticky;
  position: sticky;
}
.CalendarModal_TitleEmoji__20q6O {
  font-size: 3rem;
  margin-right: 1rem;
  margin-top: -0.5rem;
}
.CalendarModal_Substeps__HHjFY {
  padding: 1rem 1rem;
}
.CalendarModal_Substep__14cy9 {
  color: #292f33;
  font-size: 1rem;
  margin-bottom: 2rem;
}
.CalendarModal_Description__1D0E4 {
  display: flex;
  margin-bottom: 0.3rem;
}
.CalendarModal_Emoji__Badag {
  font-size: 2rem;
  margin-right: 0.5rem;
}

/* large tablets & laptops */
@media screen and (min-width: 600px) {
}

/* large tablets & laptops */
@media screen and (min-width: 768px) {
}

/* large tablets & laptops */
@media screen and (min-width: 1200px) {
}

/* Very large screens */
@media screen and (min-width: 1500px) {
}

.CalendarMobile_Calendar__3Zq8j {
  margin: 4rem 4rem;
}

.CalendarMobile_CalendarTitle__2IMKL {
  font-size: 1.25rem;
  color: #292f33;
}
.CalendarMobile_CalendarTitle__2IMKL span {
  font-weight: 700;
}

.CalendarMobile_Main__34MS2 {
  display: flex;
  margin-top: 2rem;
}

.CalendarMobile_CardsList__CdTWq {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
.CalendarMobile_Modal__3fhKB {
  width: 80%;
  outline: none;
}

/* large tablets & laptops */
@media screen and (min-width: 1200px) {
  .CalendarMobile_Calendar__3Zq8j {
    margin: 4rem 11rem;
  }
}

/* Very large screens */
@media screen and (min-width: 1500px) {
}

.Projet_Projet__2YEjM {
  padding: 2rem 1.5rem;
  margin-bottom: 2rem;
}
.Projet_Projet__2YEjM p {
  margin-bottom: 1.5rem;
  color: #5b5855;
}
.Projet_Articles__MFVub {
  margin-bottom: 1.5rem;
}
.Projet_ArticlesHeader__1Sbr- {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.Projet_Description__kIWFT p {
  text-align: justify;
}

.Projet_ActionButton__1G0ou {
  display: none;
}
.Projet_ActionButton__1G0ou p {
  padding: 0.625rem 1.25rem;
  border-radius: 30px;
  font-weight: 700;
  font-size: 0.875rem;
  color: white;
  background: #3b4578;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.4);
  border-radius: 30px;
  cursor: pointer;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin: 0 auto;
  margin-top: 4rem;
}
.Projet_ActionButtonSvg__1pMEt {
  display: none;
}

.Projet_DescriptionSvg__2erm2 {
  max-width: 20rem;
  margin: 0 auto;
}

/* large tablets & laptops */
@media screen and (min-width: 600px) {
}

/* large tablets & laptops */
@media screen and (min-width: 768px) {
  .Projet_Projet__2YEjM {
    display: flex;
    flex-flow: row;
    padding: 4rem 4rem;
  }
  .Projet_Description__kIWFT {
    margin-right: 5rem;
    padding-top: 3rem;
  }
  .Projet_DescriptionSvg__2erm2 {
    display: none;
  }
  .Projet_DescriptionSection__1XhXi {
    position: relative;
  }
  .Projet_Description__kIWFT {
    /* margin-left: 13vw; */
    margin-right: 5vw;
  }

  .Projet_ActionButton__1G0ou {
    display: none;
  }

  .Projet_BackgroundShape__3pJIM {
    position: absolute;
    width: 37vw;
    height: 37vw;
    border-radius: 65% 37% 35% 64% / 58% 55% 37% 41%;
    background-color: #e3e7f1;
    /* background-color: lightcoral; */
    z-index: -2;
    right: -4rem;
    top: -4rem;
  }
}

/* large tablets & laptops */
@media screen and (min-width: 1200px) {
  .Projet_Projet__2YEjM {
    padding: 4rem 11rem;
    padding-right: 5rem;
  }

  .Projet_DescriptionSection__1XhXi {
    position: relative;
  }
  .Projet_Description__kIWFT {
    margin-left: 13vw;
    margin-right: 5vw;
  }
  .Projet_DescriptionSvg__2erm2 {
    display: block;
    width: 25vw;
    position: absolute;
    left: -11rem;
    top: -3rem;
    z-index: -1;
  }

  .Projet_ActionButton__1G0ou {
    display: block;
    position: relative;
  }

  .Projet_ActionButtonSvg__1pMEt {
    display: block;
    width: 15rem;
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: -1;
    overflow: hidden;
  }
  .Projet_ActionButtonSvg__1pMEt::before {
    content: "";
    -webkit-transform: translate(-50%, 50%);
            transform: translate(-50%, 50%);
    position: absolute;
    height: 100%;
    width: 100%;
  }

  .Projet_BackgroundShape__3pJIM {
    position: absolute;
    width: 37vw;
    height: 37vw;
    border-radius: 65% 37% 35% 64% / 58% 55% 37% 41%;
    background-color: #e3e7f1;
    /* background-color: lightcoral; */
    z-index: -2;
    right: -4rem;
    top: -4rem;
  }
}

/* Very large screens */
@media screen and (min-width: 1500px) {
}

.ArticleModal_ArticleModal__3UxPP {
  padding: 0;
  border-radius: 10px;

  padding: 1rem;
  outline: none;
  background-color: white;
  outline: none;
  overflow: scroll;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.ArticleModal_ArticleModal__3UxPP::-webkit-scrollbar {
  display: none;
}

.ArticleModal_ModalContent__1bJkY {
  width: 80%;
  outline: none;
}

/* large tablets & laptops */
@media screen and (min-width: 600px) {
}

/* large tablets & laptops */
@media screen and (min-width: 768px) {
  .ArticleModal_ModalContent__1bJkY {
    width: 50%;
  }
}

/* large tablets & laptops */
@media screen and (min-width: 1200px) {
  .ArticleModal_ModalContent__1bJkY {
    width: 40%;
  }
}

/* Very large screens */
@media screen and (min-width: 1500px) {
}

.Articles_ArticleCard__3GrNh {
  background: #ffffff;
  padding: 1rem;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.4);
  border-radius: 18px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 1.5rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  max-width: 22rem;
}
.Articles_ArticleCard__3GrNh p {
  margin: 0;
  color: #292f33;
  font-size: 1rem;
}
.Articles_Emoji__2rnlG {
  margin-right: 0.5rem;
  font-size: 1.8rem;
}
.Articles_DeleteButton__HRYMP {
  position: absolute;
  top: 0.5rem;
  right: 1rem;
}

/* large tablets & laptops */
@media screen and (min-width: 600px) {
}

/* large tablets & laptops */
@media screen and (min-width: 768px) {
  .Articles_ArticleCard__3GrNh {
    min-width: 23rem;
  }
}

/* large tablets & laptops */
@media screen and (min-width: 1200px) {
  .Articles_ArticleCard__3GrNh {
    min-width: 20rem;
  }
}

/* Very large screens */
@media screen and (min-width: 1500px) {
}

.UploadDocument_AddButton__26pOP p {
  background: #ee9834;
  color: white;
  border: 1px solid #ee9834;
  cursor: pointer;
  padding: 0.625rem 1.25rem;
  border-radius: 30px;
  font-weight: 700;
  font-size: 0.875rem;
}
.UploadDocument_ModalContent__iGs-z {
  background-color: #f3f2f0;
  padding: 2rem;
  border-radius: 10px;
  width: 30rem;
  outline: none;
}
.UploadDocument_QuestionTitle__zVSW4 {
  margin-top: 0.5em;
  display: flex;
  /* justify-content: center; */
  align-items: center;
}
.UploadDocument_QuestionTitle__zVSW4 p {
  color: #2a2f39;
  margin-left: 0.4em;
}
.UploadDocument_TitleField__1cEWu {
  margin-top: 1rem;
}
.UploadDocument_CloudIcon__eEgFZ {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 0.6rem;
  justify-content: center;
}
.UploadDocument_Buttons__2ph-p {
  margin-top: 2rem;
  display: flex;
  justify-content: space-between;
}

@media screen and (max-width: 768px) {
  .UploadDocument_ModalContent__iGs-z {
    width: 80%;
    outline: none;
  }
}

.ProfileSetup_Wrapper__2AFEQ {
  height: 100vh;
  background-color: #e5e5e5;
  overflow: hidden;
  overflow-y: auto;
}
.ProfileSetup_ProfileSetupContainer__1iGCV {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  min-height: 100%;
  padding: 20px;
}

.ProfileSetup_ProfileSetup__3NxrA {
  background-color: white;
  border-radius: 10px;
  padding: 30px;
  width: 90%;
  max-width: 450px;
  position: relative;
  box-shadow: 0 30px 60px 0 rgba(0, 0, 0, 0.3);
}

.ProfileSetup_ImgWrapper__2yv2f {
  width: 60%;
  margin: 0 auto;
  padding-bottom: 20px;
}
.ProfileSetup_ImgWrapper__2yv2f img {
  width: 100%;
}

.ProfileSetup_Title__1w5Ac {
  font-size: 1.5em;
  text-align: left;
  line-height: 1.2em;
  margin-bottom: 10px;
}
.ProfileSetup_Title__1w5Ac span {
  background: -webkit-gradient(
    linear,
    left top, left bottom,
    color-stop(60%, rgba(255, 255, 255, 0)),
    color-stop(40%, rgba(0, 78, 124, 0.178))
  );
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0) 60%,
    rgba(0, 78, 124, 0.178) 40%
  );
}
.ProfileSetup_CircularProgress__2CoWg {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.ProfileSetup_QuestionContainer__2bHli {
  height: 15rem;
  margin-bottom: 3.5rem;
}

/* START =========== Buttons */
.ProfileSetup_ButtonsContainer__1gF0u {
  position: absolute;
  left: 0;
  bottom: 2rem;
  right: 0;
  text-align: center;
}
.ProfileSetup_Button__3iOnJ {
  display: inline-block;
  margin: 0 1rem;
}
.ProfileSetup_SubmitButton__2zdOu {
  line-height: 0;
  width: auto;
  padding: 0 12px;
  height: 48px;
  margin: 0 8px;
  font-size: 1.2rem;

  background: #ebecf0;
  border: 1px solid rgba(255, 255, 255, 0.7);
  box-sizing: border-box;
  box-shadow: 5px 5px 10px #a6abbd, -5px -5px 10px #fafbff;
  border-radius: 10px;

  outline: none;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
}

.NextButton_NextButton__1rRao {
  line-height: 0;
  width: 58px;
  height: 58px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-size: 1.5em;
  outline: none;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
  color: #777;
  background: #3d9ec73f;
  border: none;
  border-radius: 50px;
}

.NextButton_NextButton__1rRao:hover {
  background-color: #3d9dc7;
  color: white;
}

.NextButton_NextButton__1rRao:active {
  background-color: #3d9dc7;
  color: white;
  box-shadow: inset 1px 1px 2px #80012d, inset -1px -1px 2px #d6929850;
}
.NextButton_NextButton__1rRao i {
  padding-left: 2px;
}

.BackButton_BackButton__2IRnx {
  line-height: 0;
  width: 58px;
  height: 58px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-size: 1.5em;
  outline: none;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
  color: #777;
  background: #3d9ec73f;
  border: none;
  border-radius: 50px;
}

.BackButton_BackButton__2IRnx:hover {
  background-color: #3d9dc7;
  color: white;
}

.BackButton_BackButton__2IRnx:active {
  background-color: #3d9dc7;
  color: white;
  box-shadow: inset 1px 1px 2px #80012d, inset -1px -1px 2px #d6929850;
}
.BackButton_BackButton__2IRnx i {
  padding-right: 2px;
}

.CircularProgressBar_Circle__12sqP {
  position: relative;
  width: 50px;
  height: 50px;
  border: 4px solid #c9d100;
  box-sizing: border-box;
  border-radius: 50%;
  /* animation-name: progress;
  animation-duration: 10s;
  animation-iteration-count: infinite;
  animation-timing-function: linear; */
  -webkit-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}

.CircularProgressBar_Circle__12sqP::after {
  content: "";
  position: absolute;
  top: -21px;
  left: -21px;
  width: 180px;
  height: 180px;
  border: 22px solid #fff;
  box-sizing: border-box;
  border-radius: 50%;
  -webkit-clip-path: polygon(50% 50%, 50% 0, 50% 0, 50% 0, 50% 0, 50% 0, 50% 0);
          clip-path: polygon(50% 50%, 50% 0, 50% 0, 50% 0, 50% 0, 50% 0, 50% 0);
  /* animation-name: progress_after;
  animation-duration: 10s;
  animation-iteration-count: infinite;
  animation-timing-function: linear; */
  -webkit-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}

.CircularProgressBar_Etape__2g1p3 {
  margin-top: 0.35em;
  font-weight: bold;
  position: absolute;
  width: 100%;
  text-align: center;
  font-size: 1.5em;
}

/* SART ------------Basic Input--------------- */
.Input_InputGroup__2GoaG {
  position: relative;
  width: 80%;
  margin-bottom: 40px;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
}
.Input_Input__2ml8d {
  font-size: 18px;
  font-family: inherit;
  padding: 10px 10px 10px 5px;
  display: block;
  width: 100%;
  border: none;
  border-bottom: 1px solid #757575;
  font-family: Arial, Helvetica, sans-serif;
}
.Input_Input__2ml8d:focus {
  outline: none;
}
.Input_Phone__2doU3 {
  padding-left: 35px;
}
.Input_InputGroup__2GoaG i {
  position: absolute;
  font-size: 1.3em;
  top: 12px;
  left: -35px;
  color: #999;
  cursor: pointer;
}
/* LABEL ======= */
.Input_InputLabel__3te0j {
  color: #999;
  font-size: 18px;
  font-weight: normal;
  position: absolute;
  pointer-events: none;
  left: 5px;
  top: 10px;
  transition: 0.2s ease all;
  -moz-transition: 0.2s ease all;
  -webkit-transition: 0.2s ease all;
}
/* active state */
.Input_Input__2ml8d:focus ~ .Input_InputLabel__3te0j,
.Input_Input__2ml8d:valid ~ .Input_InputLabel__3te0j {
  top: -20px;
  font-size: 14px;
  color: #e31869;
}
/* BOTTOM BARS ========= */
.Input_Bar__3PlAy {
  position: relative;
  display: block;
  width: 100%;
}
.Input_Bar__3PlAy:before,
.Input_Bar__3PlAy:after {
  content: "";
  height: 2px;
  width: 0px;
  bottom: 1px;
  position: absolute;
  background: #e31869;
  transition: 0.2s ease all;
  -moz-transition: 0.2s ease all;
  -webkit-transition: 0.2s ease all;
}
.Input_Bar__3PlAy:before {
  left: 50%;
}
.Input_Bar__3PlAy:after {
  right: 50%;
}
/* active state */
.Input_Input__2ml8d:focus ~ .Input_Bar__3PlAy:before,
.Input_Input__2ml8d:focus ~ .Input_Bar__3PlAy:after {
  width: 50%;
}

/* HIGHLIGHTER ============ */
.Input_Highlight__BE0_T {
  position: absolute;
  height: 60%;
  width: 100px;
  top: 25%;
  left: 0;
  pointer-events: none;
  opacity: 0.5;
}
/* active state */
.Input_Input__2ml8d:focus ~ .Input_Highlight__BE0_T {
  animation: Input_inputHighlighter__XtMCP 0.3s ease;
  -webkit-animation: Input_inputHighlighter__XtMCP 0.3s ease;
  -moz-animation: Input_inputHighlighter__XtMCP 0.3s ease;
}
/* Animations */
@keyframes Input_inputHighlighter__XtMCP {
  from {
    background: #e31869;
  }
  to {
    width: 0;
    background: transparent;
  }
}
@-webkit-keyframes Input_inputHighlighter__XtMCP {
  from {
    background: #e31869;
  }
  to {
    width: 0;
    background: transparent;
  }
}

/* Indicatif ====== */
.Input_Indicatif__9XanX {
  position: absolute;
  font-size: 18px;
  top: 24%;
  left: 0;
  opacity: 0;
  font-family: Arial, Helvetica, sans-serif;
}
/* active state */
.Input_Input__2ml8d:focus ~ .Input_Indicatif__9XanX,
.Input_Input__2ml8d:valid ~ .Input_Indicatif__9XanX {
  opacity: 1;
}

/* START -----------Radio Button----------- */

.Input_RadioFormElement__21pA4 {
  /* margin-top: 10px; */
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
}
.Input_RadioFormElement__21pA4::-webkit-scrollbar {
  display: none; /* Safari & Chrome*/
}

.Input_RadioDivElement__2JKgB {
  flex: 0 0 auto;
  margin: 20px 0;
  margin-right: 20px;
  width: 60px;
  height: 60px;
  position: relative;
}
.Input_RadioDivElement__2JKgB:first-child {
  margin-left: 10vw;
}

.Input_RadioDivElement__2JKgB input {
  opacity: 0;
}

.Input_RadioDivElement__2JKgB label {
  width: 100%;
  height: 100%;
  font-size: 1em;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  text-align: center;
  line-height: 1em;
  background: #ebecf0;
  border: 1px solid rgba(255, 255, 255, 0.4);
  box-sizing: border-box;
  /* OmbreOUT */
  box-shadow: 5px 5px 10px #a6abbd, -5px -5px 10px #fafbff;
  border-radius: 10px;
  cursor: pointer;
}
.Input_RadioDivElement__2JKgB span {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

.Input_RadioDivElement__2JKgB input[type="radio"]:checked + label {
  box-shadow: inset 1px 1px 2px #babecc, inset -1px -1px 2px #fff;
  color: #e31869;
  font-weight: bold;
}

/* END -----------Radio Button----------- */

/* Small tablets */

.QuestionTwo_QuestionTwo__4foSN {
  margin: 1.3em 0;
  text-align: center;
}
.QuestionTwo_QuestionTwo__4foSN p {
  margin: 20px;
  font-size: 1.1rem;
}

.Radio_FormTitle__3c7dJ {
  margin-top: 20px;
  margin-bottom: 10px;
  font-size: 1.1rem;
  text-align: center;
}

.Radio_Form__39VwH {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.Radio_FormElement__2Oemz {
  margin: 5px 5px;
  width: 3.2em;
  height: 3.2em;
  position: relative;
}

.Radio_FormElement__2Oemz input[type="radio"] {
  display: none;
}

.Radio_FormElement__2Oemz label {
  position: absolute;
  height: 100%;
  width: 100%;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  border-radius: 15%;
  border: none;
  background-color: #3d9ec756;
  color: #777;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.Radio_FormElement__2Oemz input[type="radio"]:checked + label {
  background-color: #3d9dc7;
  color: white;
}

/* .Travaux {
  overflow-x: hidden;
} */
.Travaux_SubHeader__1ZJP2 {
  background-color: #ee9834;
  padding: 0;
  margin-top: -0.1rem;
  padding-top: 2rem;
}
.Travaux_HomeLink__1V1vK {
  color: white;
  display: flex;
  justify-content: center;
}

.Travaux_HomeLink__1V1vK a {
  color: white;
  font-size: 0.9rem;
  margin-left: 0.4rem;
  text-decoration: underline;
}
.Travaux_Main__ER6uj {
  padding: 0 0.2em;
  margin-bottom: 5em;
}
.Travaux_CardListTitle__2gXF0 {
  margin-top: 3rem;
  margin-bottom: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.Travaux_CardListTitle__2gXF0 p {
  font-size: 1.2rem;
  text-align: center;
  color: #828489;
  margin-bottom: 1rem;
}
.Travaux_CardListTitle__2gXF0 span {
  font-size: 1.2rem;
  font-weight: bold;
  color: #292f33;
}
.Travaux_Travaux__17BhQ {
  /* margin-bottom: 4em; */
}

/* large tablets & laptops */
@media screen and (min-width: 768px) {
  .Travaux_SubHeader__1ZJP2 {
    padding: 0 4rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .Travaux_CardListTitle__2gXF0 {
    flex-direction: row;
    justify-content: space-between;
  }
  .Travaux_CardListTitle__2gXF0 p {
    font-size: 1.4rem;
  }
  .Travaux_CardListTitle__2gXF0 span {
    font-size: 1.4rem;
  }
  .Travaux_Travaux__17BhQ {
    margin: 0 4rem;
    margin-top: 1em;
    /* margin-bottom: 4em; */
  }
  .Travaux_MainCards__2ai3g {
    display: flex;
    position: relative;
    min-height: 20em;
  }
  /* .NoCard {
    background-color: #f3f2f08a;
    border-radius: 14px;
    margin-left: 3em;
    margin-bottom: 2em;
    position: absolute;
    height: 30em;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    width: 20em;
  } */
}

/* large tablets & laptops */
@media screen and (min-width: 1200px) {
  .Travaux_SubHeader__1ZJP2 {
    padding: 0 11rem;
  }
  .Travaux_Travaux__17BhQ {
    margin: 0 11em;
    /* margin-bottom: 4em; */
  }
}

.IntroModal_IntroModal__10V84 {
  height: 50vh;
  overflow-y: scroll;
  margin-bottom: 3em;
}

.IntroModal_IntroTitle__3AByl {
  /* margin-right: 1em; */
}

.IntroModal_IntroDescription__1j_2P {
  margin-right: 1em;
}

.IntroModal_IntroButton__2EWG1 {
  margin-top: 1.5em;
  text-align: center;
  margin-right: 1em;
  margin-bottom: 1em;
  position: fixed;
  bottom: 0.2em;
  width: 90%;
}

.IntroModal_Personnalisable__3CUMT {
  cursor: pointer;
  /* font-weight: 700; */
  background: -webkit-gradient(linear, left top, left bottom, color-stop(60%, rgba(255, 255, 255, 0)), color-stop(40%, #bbd5ff));
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 60%, #bbd5ff 40%);
}

.IntroModal_Informative__2L5IL {
  cursor: pointer;
  /* font-weight: 700; */
  background: -webkit-gradient(linear, left top, left bottom, color-stop(60%, rgba(255, 255, 255, 0)), color-stop(40%, #fcd79d));
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 60%, #fcd79d 40%);
}

.Toolbar_Header__o_F9M {
  background-color: #3b4578;
  padding: 2rem 1.5rem;
}

.Toolbar_Toolbar__jfejK {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.Toolbar_Title__2TEc1 {
  margin-top: 1.5rem;
  color: white;
  font-family: "Nunito Sans";
  line-height: 1.7rem;
}
.Toolbar_Title__2TEc1 h3 {
  font-size: 1.8rem;
  text-transform: uppercase;
  font-weight: 900;
}
.Toolbar_Title__2TEc1 h4 {
  font-size: 1.6rem;
  font-weight: 600;
}

.Toolbar_Description__215XK {
  color: white;
  margin-top: 2rem;
  font-weight: 400;
  font-size: 1.125rem;
}

/* large tablets & laptops */
@media screen and (min-width: 768px) {
  .Toolbar_Header__o_F9M {
    padding: 4rem 4rem;
  }
  .Toolbar_Title__2TEc1 h3 {
    font-size: 1.8rem;
  }
  .Toolbar_Title__2TEc1 {
    display: flex;
  }
  .Toolbar_Title__2TEc1 h4 {
    margin-left: 1rem;
  }
  .Toolbar_Description__215XK {
    width: 23rem;
  }
}

/* large tablets & laptops */
@media screen and (min-width: 1200px) {
  .Toolbar_Header__o_F9M {
    padding: 2rem 11rem;
  }

  .Toolbar_Title__2TEc1 h3 {
    font-size: 2rem;
  }
}

.Filter_Filter__Oov9K {
}
.Filter_Filter__Oov9K p {
  font-size: 0.9rem;
}
.Filter_Filter__Oov9K span {
  font-size: 1.5rem;
  font-weight: bold;
}

.Filter_Categories__eIqdO form {
  display: flex;
  justify-content: center;
  align-self: center;
  padding: 1rem 0;
  /* flex-wrap: nowrap;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: none; 
  scrollbar-width: none;  */
}
/* .Categories form::-webkit-scrollbar {
  display: none; 
} */

.Filter_Personnalisable__3prEd {
  margin: 0 0.5em;
  padding: 0.8em 0;
  flex: 0 0 auto;
}

.Filter_Personnalisable__3prEd label {
  background: #bcbecc;
  color: #5b5855;
  font-size: 0.85rem;
  border-radius: 24px;
  padding: 10px 15px;
  cursor: pointer;
}

.Filter_Personnalisable__3prEd input[type="checkbox"] {
  display: none;
}

.Filter_Personnalisable__3prEd input[type="checkbox"]:checked + label {
  background: white;
  color: #5b5855;
}

/* =========================== */
.Filter_Informative__39p6C {
  margin: 0 0.5em;
  padding: 0.8em 0;
  flex: 0 0 auto;
}

.Filter_Informative__39p6C label {
  background: #bcbecc;
  color: #5b5855;
  font-size: 0.85rem;
  border-radius: 24px;
  padding: 10px 15px;
  cursor: pointer;
}

.Filter_Informative__39p6C input[type="checkbox"] {
  display: none;
}

.Filter_Informative__39p6C input[type="checkbox"]:checked + label {
  background: white;
  color: #5b5855;
}

/* .CardList {
  margin-bottom: 2em;
}

.List {
  display: flex;
  flex-direction: column;
  align-items: center;
} */

/* large tablets & laptops */
/* @media screen and (min-width: 768px) {
  .List {
    align-items: flex-start;
    width: 21em;
  }
} */

.CardList_CardList__3asOi {
  margin-bottom: 2em;
}

.CardList_List__x0TLE {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: center;
  padding: 0.5rem;
}

/* large tablets & laptops */
@media screen and (min-width: 768px) {
  /* .List {
    align-items: flex-start;
    width: 21em;
  } */
}

.Card_Card__unv0p {
  background-color: white;
  display: flex;
  border-radius: 14px;
  position: relative;
  max-width: 22em;
  margin: 0.5rem;
  margin-bottom: 1.5rem;
  cursor: pointer;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25), 0px 4px 4px rgba(0, 0, 0, 0.1);
}
.Card_SelectedCard__37dy9 {
  border: 2px solid #e57373;
}

.Card_Thumbnail__1TnGG {
  width: 7em;
  height: 7em;
  flex: 0 0 7em;
  background-color: white;
  margin: 0.5em;
  border-radius: 14px;
  align-self: center;
}
.Card_Thumbnail__1TnGG img {
  border-radius: 14px;
  width: 100%;
}

.Card_Main__1jOON {
  margin: 0.5em 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.Card_Titre__1wlRy {
  font-size: 1rem;
  padding-right: 0.5rem;
}
.Card_Titre__1wlRy span {
  font-weight: 700;
}

.Card_Description__1hF_D {
  margin-right: 4em;
  font-size: 0.6rem;
}

.Card_Icon__DQPOC {
  background-color: #f3f2f0;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 0.4rem;
  border-radius: 0 14px 14px 0;
}

.Card_TagContainer__1Ge9p {
  font-size: 0.6rem;
  color: white;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}
.Card_Tag__213lo {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.25rem 0.5rem;
  border-radius: 40px;
}
.Card_Tag__213lo p {
  padding: 0 0.2rem;
}

.CardCreateReclamation_Card__vSy6C {
  background-color: white;
  display: flex;
  border-radius: 14px;
  position: relative;
  max-width: 22em;
  margin: 0.5rem;
  margin-bottom: 1.5rem;
  cursor: pointer;

  border: 3px solid #3b4578;
  box-sizing: border-box;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25), 0px 4px 4px rgba(0, 0, 0, 0.1);
}
.CardCreateReclamation_SelectedCard__2tvTL {
  border: 2px solid #e57373;
}

.CardCreateReclamation_Thumbnail__1mQ2u {
  width: 7em;
  height: 7em;
  flex: 0 0 7em;
  background-color: white;
  margin: 0.5em;
  border-radius: 14px;
  align-self: center;
}
.CardCreateReclamation_Thumbnail__1mQ2u img {
  border-radius: 14px;
  width: 100%;
}

.CardCreateReclamation_Main__211pI {
  margin: 0.5em 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.CardCreateReclamation_Titre__NGuur {
  font-size: 1rem;
  padding-right: 0.5rem;
}
.CardCreateReclamation_Titre__NGuur span {
  font-weight: 700;
}

.CardCreateReclamation_Description__1qgh6 {
  margin-right: 4em;
  font-size: 0.6rem;
}

.CardCreateReclamation_Icon__2y7Nq {
  background-color: #f3f2f0;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 0.4rem;
  border-radius: 0 14px 14px 0;
}

.CardCreateReclamation_TagContainer__TUcpf {
  font-size: 0.6rem;
  color: white;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}
.CardCreateReclamation_Tag__o5O-q {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.25rem 0.5rem;
  border-radius: 40px;
}
.CardCreateReclamation_Tag__o5O-q p {
  padding: 0 0.2rem;
}

.ModalCreateReclamation_ModalCreateReclamation__2kIge {
  padding: 0;
  border-radius: 10px;
  padding: 1rem;
  outline: none;
  background-color: white;
  outline: none;
  overflow: scroll;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.ModalCreateReclamation_ModalCreateReclamation__2kIge::-webkit-scrollbar {
  display: none;
}

.ModalCreateReclamation_ModalContent__3kTvd {
  width: 100%;
  height: 100%;
  outline: none;
  background-color: #f3f2f0;
  padding: 2rem;
}

.ModalCreateReclamation_ModalTitle__3YxrY {
  margin-top: 1.4rem;
  margin-bottom: 1.7rem;
  font-size: 1.5rem;
  font-weight: 700;
  color: #3b4578;
}

/* large tablets & laptops */
@media screen and (min-width: 768px) {
  .ModalCreateReclamation_ModalContent__3kTvd {
    position: relative;
    border-radius: 10px;
    width: 30rem;
    height: 90%;
    max-height: 50rem;
    outline: none;
  }
}

/* large tablets & laptops */
@media screen and (min-width: 1200px) {
}

/* Very large screens */
@media screen and (min-width: 1500px) {
}

.ReclamationContent_IntroImage__1m6zD {
  margin: auto;
  margin-top: 4rem;
}

.ReclamationContent_Buttons__1oEmP {
  position: absolute;
  bottom: 2rem;
  right: 2rem;
  left: 2rem;
  display: flex;
  justify-content: space-between;
}

.ReclamationContent_QuestionTitle__2aQB- {
  margin-top: 0.5em;
  display: flex;
  /* justify-content: center; */
  align-items: center;
}
.ReclamationContent_QuestionTitle__2aQB- p {
  color: #2a2f39;
  margin-left: 0.4em;
}

.ReclamationContent_RadioFormTwo__2kjZ2 {
  display: flex;
  flex-wrap: wrap;
  margin-top: 1rem;
  justify-content: space-between;
}

.ReclamationContent_RadioElementTwo__Xng2j {
  margin-top: 1rem;
  /*flex: 1 0 32%; */
}

.ReclamationContent_RadioElementTwo__Xng2j input[type="radio"] {
  display: none;
}

.ReclamationContent_RadioElementTwo__Xng2j input[type="radio"] + label + p {
  text-align: left;
  font-size: 0.8rem;
  width: 5rem;
}

.ReclamationContent_RadioElementTwo__Xng2j input[type="radio"] + label {
  background-position: center center;
  background-size: contain;
  height: 5rem;
  width: 5rem;
  display: inline-block;
  position: relative;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.4);
  border-radius: 5px;
}

.ReclamationContent_RadioElementTwo__Xng2j input[type="radio"] + label:hover {
  cursor: pointer;
}

.ReclamationContent_RadioElementTwo__Xng2j input[type="radio"]:checked + label {
  border-color: #e0004d;
}

.ReclamationContent_RadioElementTwo__Xng2j input[type="radio"] + label i {
  font-size: 12px;
  position: absolute;
  left: 9.5em;
  bottom: -8px;
  color: #fff;
  background-color: #e0004d;
  border-radius: 100%;
  border: 2px solid #e0004d;
  padding: 2px;
  display: none;
}

.ReclamationContent_RadioElementTwo__Xng2j input[type="radio"]:checked + label i {
  display: inline-block;
}
.ReclamationContent_RadioElementTwo__Xng2j input[type="radio"]:checked + label + p {
  font-weight: bold;
}

.ReclamationContent_TitleField__1KEOa {
  margin-top: 1rem;
}
.ReclamationContent_DescriptionField__MdWU9 {
  margin-top: 1rem;
}

.ReclamationContent_ImagesRow__3pYg9 {
  margin-top: 0.5rem;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
}
.ReclamationContent_ImagePlaceholder__1WGz7 {
  background: #ffffff;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.4);
  border-radius: 5px;
  height: 5rem;
  width: 5rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
.ReclamationContent_ImageUploaded__3NIn_ {
  border-radius: 5px;
  height: 5rem;
  width: 5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.ReclamationContent_RecapTags__vgiXu {
  display: flex;
}
.ReclamationContent_RecapTags__vgiXu p {
  padding: 0.5rem;
  background-color: #ee9834;
  border-radius: 16px;
  margin-right: 1rem;
  color: white;
  font-size: 0.8rem;
}
.ReclamationContent_RecapTitle__2hQE2 {
  margin-top: 1rem;
}
.ReclamationContent_TitleArea__3fL9W {
  margin-top: 0.5rem;
  padding: 0.5rem;
  background-color: white;
  height: 2.5rem;
  border-radius: 8px;
  overflow-y: scroll;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.ReclamationContent_TitleArea__3fL9W::-webkit-scrollbar {
  display: none;
}
.ReclamationContent_RecapDescription__16oON {
  margin-top: 1rem;
}
.ReclamationContent_DescriptionArea__1cqMX {
  margin-top: 0.5rem;
  padding: 0.5rem;
  background-color: white;
  height: 7rem;
  border-radius: 8px;
  overflow-y: scroll;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.ReclamationContent_DescriptionArea__1cqMX::-webkit-scrollbar {
  display: none;
}
.ReclamationContent_RecapImagesRow__2EH9A {
  margin-top: 1rem;
  display: flex;
  justify-content: space-evenly;
}

/* large tablets & laptops */
@media screen and (min-width: 401px) {
  .ReclamationContent_RadioElementTwo__Xng2j {
    margin-top: 1rem;
    padding-left: 2rem;
    flex: 1 0 32%;
  }
}

.CardUserReclamation_Card__1pczC {
  background-color: white;
  display: flex;
  border-radius: 14px;
  position: relative;
  max-width: 22em;
  margin: 0.5rem;
  margin-bottom: 1.5rem;
  cursor: pointer;
  border: 3px solid #3b4578;
  box-sizing: border-box;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25), 0px 4px 4px rgba(0, 0, 0, 0.1);
  border-radius: 14px;
}
.CardUserReclamation_SelectedCard__2KnMk {
  border: 2px solid #e57373;
}

.CardUserReclamation_Thumbnail__39lfr {
  width: 7em;
  height: 7em;
  flex: 0 0 7em;
  background-color: white;
  margin: 0.5em;
  border-radius: 14px;
  align-self: center;
}
.CardUserReclamation_Thumbnail__39lfr img {
  border-radius: 14px;
  width: 100%;
}

.CardUserReclamation_Main__2iXl4 {
  margin: 0.5em 0;
  min-width: 11rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.CardUserReclamation_Titre__2xkls {
  font-size: 1rem;
  padding-right: 0.5rem;
}
.CardUserReclamation_Titre__2xkls span {
  font-weight: 700;
}

.CardUserReclamation_Description__g-RmI {
  margin-right: 4em;
  font-size: 0.6rem;
}

.CardUserReclamation_Icon__cWbGh {
  background-color: #f3f2f0;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 0.4rem;
  border-radius: 0 14px 14px 0;
}

.CardUserReclamation_TagContainer__2928k {
  font-size: 0.6rem;
  color: white;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}
.CardUserReclamation_Tag__24b0V {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.25rem 0.5rem;
  border-radius: 40px;
}
.CardUserReclamation_Tag__24b0V p {
  padding: 0 0.2rem;
}

.CardUserReclamation_Notification__2tiEZ {
  position: absolute;
  top: -1.2rem;
  left: 1rem;
  padding: 0.3rem;
  border-radius: 100px;
  background-color: #fcb34a;
}

.ModalUserReclamation_ModalCreateReclamation__g4P2k {
  padding: 0;
  border-radius: 10px;
  padding: 1rem;
  outline: none;
  background-color: white;
  outline: none;
  overflow: scroll;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.ModalUserReclamation_ModalCreateReclamation__g4P2k::-webkit-scrollbar {
  display: none;
}

.ModalUserReclamation_ModalContent__3oGWu {
  width: 100%;
  height: 100%;
  outline: none;
  background-color: #f3f2f0;
  padding: 2rem;
}

.ModalUserReclamation_ModalTitle__4A6Hm {
  margin-top: 1.4rem;
  margin-bottom: 1.7rem;
  font-size: 1.5rem;
  font-weight: 700;
  color: #3b4578;
}

/* large tablets & laptops */
@media screen and (min-width: 768px) {
  .ModalUserReclamation_ModalContent__3oGWu {
    position: relative;
    border-radius: 10px;
    width: 30rem;
    height: 90%;
    max-height: 50rem;
    outline: none;
  }
}

/* large tablets & laptops */
@media screen and (min-width: 1200px) {
}

/* Very large screens */
@media screen and (min-width: 1500px) {
}

.ReclamationContent_IntroImage__puLDx {
  margin: auto;
  margin-top: 4rem;
}

.ReclamationContent_Buttons__3Trxn {
  position: absolute;
  bottom: 2rem;
  right: 2rem;
  left: 2rem;
  display: flex;
  justify-content: space-between;
}

.ReclamationContent_QuestionTitle__2Bww6 {
  margin-top: 0.5em;
  display: flex;
  /* justify-content: center; */
  align-items: center;
}
.ReclamationContent_QuestionTitle__2Bww6 p {
  color: #2a2f39;
  margin-left: 0.4em;
}

.ReclamationContent_RadioFormTwo__OZsjM {
  display: flex;
  flex-wrap: wrap;
  margin-top: 1rem;
  justify-content: space-between;
}

.ReclamationContent_RadioElementTwo__3K8hn {
  margin-top: 1rem;
  /*flex: 1 0 32%; */
}

.ReclamationContent_RadioElementTwo__3K8hn input[type="radio"] {
  display: none;
}

.ReclamationContent_RadioElementTwo__3K8hn input[type="radio"] + label + p {
  text-align: left;
  font-size: 0.8rem;
  width: 5rem;
}

.ReclamationContent_RadioElementTwo__3K8hn input[type="radio"] + label {
  background-position: center center;
  background-size: contain;
  height: 5rem;
  width: 5rem;
  display: inline-block;
  position: relative;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.4);
  border-radius: 5px;
}

.ReclamationContent_RadioElementTwo__3K8hn input[type="radio"] + label:hover {
  cursor: pointer;
}

.ReclamationContent_RadioElementTwo__3K8hn input[type="radio"]:checked + label {
  border-color: #e0004d;
}

.ReclamationContent_RadioElementTwo__3K8hn input[type="radio"] + label i {
  font-size: 12px;
  position: absolute;
  left: 9.5em;
  bottom: -8px;
  color: #fff;
  background-color: #e0004d;
  border-radius: 100%;
  border: 2px solid #e0004d;
  padding: 2px;
  display: none;
}

.ReclamationContent_RadioElementTwo__3K8hn input[type="radio"]:checked + label i {
  display: inline-block;
}
.ReclamationContent_RadioElementTwo__3K8hn input[type="radio"]:checked + label + p {
  font-weight: bold;
}

.ReclamationContent_TitleField__21mAp {
  margin-top: 1rem;
}
.ReclamationContent_DescriptionField__3Ebfe {
  margin-top: 1rem;
}

.ReclamationContent_ImagesRow__3FJfX {
  margin-top: 0.5rem;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
}
.ReclamationContent_ImagePlaceholder__onGGW {
  background: #ffffff;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.4);
  border-radius: 5px;
  height: 5rem;
  width: 5rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
.ReclamationContent_ImageUploaded__o2wtl {
  border-radius: 5px;
  height: 5rem;
  width: 5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.ReclamationContent_RecapTags__1meiG {
  display: flex;
}
.ReclamationContent_RecapTags__1meiG p {
  padding: 0.5rem;
  background-color: #ee9834;
  border-radius: 16px;
  margin-right: 1rem;
  color: white;
  font-size: 0.8rem;
}
.ReclamationContent_RecapTitle__3Yt8z {
  margin-top: 1rem;
}
.ReclamationContent_TitleArea__2MQxY {
  margin-top: 0.5rem;
  padding: 0.5rem;
  background-color: white;
  height: 2.5rem;
  border-radius: 8px;
  overflow-y: scroll;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.ReclamationContent_TitleArea__2MQxY::-webkit-scrollbar {
  display: none;
}
.ReclamationContent_RecapDescription__34WLN {
  margin-top: 1rem;
}
.ReclamationContent_DescriptionArea__2suyU {
  margin-top: 0.5rem;
  padding: 0.5rem;
  background-color: white;
  height: 7rem;
  border-radius: 8px;
  overflow-y: scroll;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.ReclamationContent_DescriptionArea__2suyU::-webkit-scrollbar {
  display: none;
}
.ReclamationContent_RecapImagesRow__eXUJj {
  margin-top: 1rem;
  display: flex;
  justify-content: space-evenly;
}

/* large tablets & laptops */
@media screen and (min-width: 401px) {
  .ReclamationContent_RadioElementTwo__3K8hn {
    margin-top: 1rem;
    padding-left: 2rem;
    flex: 1 0 32%;
  }
}

.CardAdminReclamation_Card__3DHbK {
  background-color: white;
  display: flex;
  border-radius: 14px;
  position: relative;
  max-width: 22em;
  margin: 0.5rem;
  margin-bottom: 1.5rem;
  cursor: pointer;
  border: 3px solid #3b4578;
  box-sizing: border-box;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25), 0px 4px 4px rgba(0, 0, 0, 0.1);
  border-radius: 14px;
}
.CardAdminReclamation_SelectedCard__1IipI {
  border: 2px solid #e57373;
}

.CardAdminReclamation_Thumbnail__24QL1 {
  width: 7em;
  height: 7em;
  flex: 0 0 7em;
  background-color: white;
  margin: 0.5em;
  border-radius: 14px;
  align-self: center;
}
.CardAdminReclamation_Thumbnail__24QL1 img {
  border-radius: 14px;
  width: 100%;
}

.CardAdminReclamation_Main__N0Rt5 {
  margin: 0.5em 0;
  min-width: 11rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.CardAdminReclamation_Titre__1jH7X {
  font-size: 1rem;
  padding-right: 0.5rem;
}
.CardAdminReclamation_Titre__1jH7X span {
  font-weight: 700;
}

.CardAdminReclamation_Description__33pR3 {
  margin-right: 4em;
  font-size: 0.6rem;
}

.CardAdminReclamation_Icon__3qRjP {
  background-color: #f3f2f0;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 0.4rem;
  border-radius: 0 14px 14px 0;
}

.CardAdminReclamation_TagContainer__1Etx2 {
  font-size: 0.6rem;
  color: white;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}
.CardAdminReclamation_Tag__1BwxY {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.25rem 0.5rem;
  border-radius: 40px;
}
.CardAdminReclamation_Tag__1BwxY p {
  padding: 0 0.2rem;
}

.CardAdminReclamation_Notification__2RNGS {
  position: absolute;
  top: -1.2rem;
  left: 1rem;
  padding: 0.3rem;
  border-radius: 100px;
  background-color: #fcb34a;
}

.ModalUserReclamation_ModalCreateReclamation__1gMy6 {
  padding: 0;
  border-radius: 10px;
  padding: 1rem;
  outline: none;
  background-color: white;
  outline: none;
  overflow: scroll;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.ModalUserReclamation_ModalCreateReclamation__1gMy6::-webkit-scrollbar {
  display: none;
}

.ModalUserReclamation_ModalContent__1MYGF {
  width: 100%;
  height: 100%;
  outline: none;
  background-color: #f3f2f0;
  padding: 2rem;
}

.ModalUserReclamation_ModalTitle__1FW9M {
  margin-top: -0.5rem;
  margin-bottom: 1.7rem;
  font-size: 0.9rem;
  font-weight: 700;
  color: #3b4578;
  display: flex;
  justify-content: space-between;
}

/* large tablets & laptops */
@media screen and (min-width: 768px) {
  .ModalUserReclamation_ModalContent__1MYGF {
    position: relative;
    border-radius: 10px;
    width: 30rem;
    height: 90%;
    max-height: 50rem;
    outline: none;
  }
}

/* large tablets & laptops */
@media screen and (min-width: 1200px) {
}

/* Very large screens */
@media screen and (min-width: 1500px) {
}

.ReclamationContent_IntroImage__1-1d_ {
  margin: auto;
  margin-top: 4rem;
}

.ReclamationContent_Buttons__1rXS8 {
  bottom: 2rem;
  right: 2rem;
  left: 2rem;
  display: flex;
  justify-content: space-between;
}

.ReclamationContent_QuestionTitle__iukCG {
  margin-top: 0.5em;
  display: flex;
  /* justify-content: center; */
  align-items: center;
}
.ReclamationContent_QuestionTitle__iukCG p {
  color: #2a2f39;
  margin-left: 0.4em;
}

.ReclamationContent_RadioFormTwo__1N7AI {
  display: flex;
  flex-wrap: wrap;
  margin-top: 1rem;
  justify-content: space-between;
}

.ReclamationContent_RadioElementTwo__1qj9s {
  margin-top: 1rem;
  /*flex: 1 0 32%; */
}

.ReclamationContent_RadioElementTwo__1qj9s input[type="radio"] {
  display: none;
}

.ReclamationContent_RadioElementTwo__1qj9s input[type="radio"] + label + p {
  text-align: left;
  font-size: 0.8rem;
  width: 5rem;
}

.ReclamationContent_RadioElementTwo__1qj9s input[type="radio"] + label {
  background-position: center center;
  background-size: contain;
  height: 5rem;
  width: 5rem;
  display: inline-block;
  position: relative;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.4);
  border-radius: 5px;
}

.ReclamationContent_RadioElementTwo__1qj9s input[type="radio"] + label:hover {
  cursor: pointer;
}

.ReclamationContent_RadioElementTwo__1qj9s input[type="radio"]:checked + label {
  border-color: #e0004d;
}

.ReclamationContent_RadioElementTwo__1qj9s input[type="radio"] + label i {
  font-size: 12px;
  position: absolute;
  left: 9.5em;
  bottom: -8px;
  color: #fff;
  background-color: #e0004d;
  border-radius: 100%;
  border: 2px solid #e0004d;
  padding: 2px;
  display: none;
}

.ReclamationContent_RadioElementTwo__1qj9s input[type="radio"]:checked + label i {
  display: inline-block;
}
.ReclamationContent_RadioElementTwo__1qj9s input[type="radio"]:checked + label + p {
  font-weight: bold;
}

.ReclamationContent_TitleField__2ZBzO {
  margin-top: 1rem;
}
.ReclamationContent_DescriptionField__2pIU6 {
  margin-top: 1rem;
}

.ReclamationContent_ImagesRow__3JjhL {
  margin-top: 0.5rem;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
}
.ReclamationContent_ImagePlaceholder__WkeKc {
  background: #ffffff;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.4);
  border-radius: 5px;
  height: 5rem;
  width: 5rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
.ReclamationContent_ImageUploaded__2pg5e {
  border-radius: 5px;
  height: 5rem;
  width: 5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  cursor: pointer;
}

.ReclamationContent_RecapTags__1owCa {
  display: flex;
}
.ReclamationContent_RecapTags__1owCa p {
  padding: 0.5rem;
  background-color: #ee9834;
  border-radius: 16px;
  margin-right: 1rem;
  color: white;
  font-size: 0.8rem;
}
.ReclamationContent_RecapTitle__pF6AL {
  margin-top: 1rem;
}
.ReclamationContent_TitleArea__2QrQw {
  margin-top: 0.5rem;
  padding: 0.5rem;
  background-color: white;
  height: 2.5rem;
  border-radius: 8px;
  overflow-y: scroll;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.ReclamationContent_TitleArea__2QrQw::-webkit-scrollbar {
  display: none;
}
.ReclamationContent_RecapDescription__2Ep9R {
  margin-top: 1rem;
}
.ReclamationContent_DescriptionArea__2pL-k {
  margin-top: 0.5rem;
  padding: 0.5rem;
  background-color: white;
  height: 5rem;
  border-radius: 8px;
  overflow-y: scroll;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.ReclamationContent_DescriptionArea__2pL-k::-webkit-scrollbar {
  display: none;
}
.ReclamationContent_RecapImagesRow__e_8vK {
  margin-top: 1rem;
  display: flex;
  justify-content: space-evenly;
}

/* large tablets & laptops */
@media screen and (min-width: 401px) {
  .ReclamationContent_RadioElementTwo__1qj9s {
    margin-top: 1rem;
    padding-left: 2rem;
    flex: 1 0 32%;
  }
}

.Footer_Footer__1oCYb {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.Footer_LogoContainer__3Zn3V {
  width: 15em;
  margin: 1em 0;
}
.Footer_LogoContainer__3Zn3V img {
  width: 100%;
}
.Footer_LegalLinks__eIxmV {
  display: flex;
  margin-top: 1em;
  margin-bottom: 3rem;
  flex-wrap: wrap;
  justify-content: center;
  padding: 0.5rem;
}
.Footer_LegalLinks__eIxmV p {
  cursor: pointer;
  margin: 0.5rem;
  font-size: 0.8rem;
  color: #828282;
  padding: 0.8rem 1rem;
  background-color: #f3f2f0;
  border-radius: 10px;
}
.Footer_Copyrights__8a1VV {
  margin: 1em 0;
  color: #5b5855;
  text-align: center;
  font-size: 0.8rem;
}
.Footer_Copyrights__8a1VV span {
  text-transform: uppercase;
  font-size: 1rem;
  font-weight: 900;
  font-family: "Nunito Sans", sans-serif;
}
.Footer_ImmobiliereLogo__6UJi6 {
  width: 12rem;
  margin-bottom: 1rem;
  margin-left: auto;
  margin-right: auto;
}

.CardModal_CardModal__1GV6a {
  position: fixed;
  z-index: 500;
  background: white;
  overflow-y: scroll;
  /* border-radius: 9px; */
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  -webkit-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}

/* large tablets & laptops */
@media screen and (min-width: 768px) {
  .CardModal_CardModal__1GV6a {
    position: static;
    overflow-y: hidden;
    background-color: #f3f2f0;
    width: auto;
    height: auto;
    padding: 2em;
    border-radius: 14px;
    margin-left: 3em;
    margin-bottom: 2em;
  }
}

/* large tablets & laptops */
@media screen and (min-width: 1400px) {
  .CardModal_CardModal__1GV6a {
    padding: 0;
    box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.2), 0px 3px 14px rgba(0, 0, 0, 0.12),
      0px 8px 10px rgba(0, 0, 0, 0.14);
    border-radius: 3px;
  }
}

.concertableLayoutOne_Card__3y8I5 {
  background-color: white;
  outline: none;
  overflow: scroll;
}

.concertableLayoutOne_CardHeader__3Cd26 {
  display: flex;
  top: 0;
  left: 0;
  margin: 2rem 2.5rem;
  align-items: center;
  justify-content: space-between;
  /* background-color: white; */
  height: 3em;
  margin-bottom: 1.5em;
}

.concertableLayoutOne_CardTitle__28qkd h3 {
  margin-top: 1em;
  font-size: 1.5rem;
  color: #292f33;
  font-weight: bold;
}
.concertableLayoutOne_CardTitle__28qkd h4 {
  font-size: 1.3rem;
  color: #5b5855;
  font-weight: normal;
}

.concertableLayoutOne_ImagePreview__2Cs4E {
  margin: 0.5em auto;
  width: 80%;
  max-width: 20em;
}
.concertableLayoutOne_ImagePreview__2Cs4E img {
  width: 100%;
}

.concertableLayoutOne_Description__2gDk2 {
  margin: 0.5rem 2.5rem;
  color: #898786;
  font-size: 0.9rem;
}

.concertableLayoutOne_CardContent__28AbJ {
  margin-top: 2rem;
  padding: 2rem 2.5em;
  background-color: #f3f2f0;
}

.concertableLayoutOne_Emoji__3IHRL {
  font-size: 1.8rem;
}

.concertableLayoutOne_QuestionsHeader__22ovt {
  color: #3b4578;
  margin-top: 0.7em;
  margin-bottom: 1.5rem;
}

.concertableLayoutOne_QuestionTitle__GUrce {
  margin-top: 0.5em;
  font-size: 1rem;
  display: flex;
  /* justify-content: center; */
  align-items: center;
}
.concertableLayoutOne_QuestionTitle__GUrce p {
  color: #2a2f39;
  margin-left: 0.4em;
}

/* ============================== radio form */
.concertableLayoutOne_RadioForm__3VwGq {
  display: flex;
  flex-wrap: wrap;
  justify-content: left;
}

.concertableLayoutOne_RadioElement__2oSbn {
  margin: 1em 0.8em;
}

.concertableLayoutOne_RadioElement__2oSbn label {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
}

.concertableLayoutOne_RadioElement__2oSbn p {
  text-align: center;
  margin-top: 0.5em;
  font-size: 0.85em;
}

.concertableLayoutOne_RadioElement__2oSbn img {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 5em;
  /* border-radius: 50%; */
  /* box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.2), 0px 3px 14px rgba(0, 0, 0, 0.12),
    0px 8px 10px rgba(0, 0, 0, 0.14); */
}

.concertableLayoutOne_RadioElement__2oSbn input {
  display: none;
}

.concertableLayoutOne_RadioElement__2oSbn input[type="radio"]:checked + label img {
  border: 3px solid #e31869;
}
.concertableLayoutOne_RadioElement__2oSbn input[type="radio"]:checked + label p {
  font-weight: bold;
}

/* TESTING ====================================================================== */
/* .RadioForm .RadioElementTwo {
  margin: 10px 5px 5px 0;
  display: inline-block;
} */

.concertableLayoutOne_RadioFormTwo__ozhUk {
  margin: 1em 0;
  display: flex;
  flex-wrap: wrap;
  /* justify-content: left; */
}

.concertableLayoutOne_RadioElementTwo__3PrEY {
  margin: 1em 1em;
}

.concertableLayoutOne_RadioElementTwo__3PrEY input[type="radio"] {
  display: none;
}

.concertableLayoutOne_RadioElementTwo__3PrEY input[type="radio"] + label + p {
  text-align: center;
  width: 8em;
}

.concertableLayoutOne_RadioElementTwo__3PrEY input[type="radio"] + label {
  background-position: center center;
  background-size: contain;
  height: 8em;
  width: 8em;
  display: inline-block;
  position: relative;
  border: 4px solid rgb(196, 196, 196);
  border-radius: 5%;
}

.concertableLayoutOne_RadioElementTwo__3PrEY input[type="radio"] + label:hover {
  cursor: pointer;
}

.concertableLayoutOne_RadioElementTwo__3PrEY input[type="radio"]:checked + label {
  border-color: #e0004d;
}

.concertableLayoutOne_RadioElementTwo__3PrEY input[type="radio"] + label i {
  font-size: 12px;
  position: absolute;
  left: 9.5em;
  bottom: -8px;
  color: #fff;
  background-color: #e0004d;
  border-radius: 100%;
  border: 2px solid #e0004d;
  padding: 2px;
  display: none;
}

.concertableLayoutOne_RadioElementTwo__3PrEY input[type="radio"]:checked + label i {
  display: inline-block;
}
.concertableLayoutOne_RadioElementTwo__3PrEY input[type="radio"]:checked + label + p {
  font-weight: bold;
}

/* ============ END RADIO FORM */

.concertableLayoutOne_CommentSection__2aGPY {
  margin-top: 2rem;
  text-align: center;
}

.concertableLayoutOne_Buttons__BxVEs {
  display: flex;
  margin: 2rem 0;
  justify-content: space-between;
}

.concertableLayoutOne_MUIForm__1acNE {
  margin-left: 2em;
}

.concertableLayoutOne_NCSection__3L67J {
  margin-top: 1.1em;
}
.concertableLayoutOne_MapImage__2xqjM {
  margin-top: 0.85em;
  /* box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.2), 0px 4px 5px rgba(0, 0, 0, 0.12),
    0px 2px 4px rgba(0, 0, 0, 0.14); */
  /* border-radius: 10px; */
}

.concertableLayoutOne_Notices__EsUkW {
  margin: 2em 0;
  font-size: 0.6rem;
}

/* Desktops */
@media screen and (min-width: 910px) {
  .concertableLayoutOne_Card__3y8I5 {
    padding: 0;
    border-radius: 10px;
    width: 80%;
    height: 90%;
    outline: none;
    display: flex;
  }
  .concertableLayoutOne_Card__3y8I5::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  .concertableLayoutOne_Card__3y8I5 {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }

  .concertableLayoutOne_Description__2gDk2 {
  }

  .concertableLayoutOne_LeftSection__1NJIS {
    background-color: white;
    width: 50%;
    padding: 1em;
    padding-bottom: 3em;
  }

  .concertableLayoutOne_RightSection__2Rh_b {
    background-color: #f3f2f0;
    width: 50%;
    height: 100%;
    position: relative;
  }

  .concertableLayoutOne_CardContent__28AbJ {
    height: 100%;
    margin-top: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
}

.NonConcertableLayoutOne_Card__34WnY {
  background-color: white;
  outline: none;
  overflow: scroll;
}

.NonConcertableLayoutOne_CardHeader__w1a2l {
  display: flex;
  top: 0;
  left: 0;
  margin: 2rem 2.5rem;
  align-items: center;
  justify-content: space-between;
  /* background-color: white; */
  height: 3em;
  margin-bottom: 1.5em;
}

.NonConcertableLayoutOne_CardTitle__Z-KIM h3 {
  margin-top: 1em;
  font-size: 1.5rem;
  color: #292f33;
  font-weight: bold;
}
.NonConcertableLayoutOne_CardTitle__Z-KIM h4 {
  font-size: 1.3rem;
  color: #5b5855;
  font-weight: normal;
}

.NonConcertableLayoutOne_VideoPreview__1ctKJ {
  /* margin: 0.5em auto;
  width: 80%;
  max-width: 20em; */
  margin: 1em auto;
  display: flex;
  justify-content: center;
}

.NonConcertableLayoutOne_Description__1L3_Q {
  margin: 0.5rem 0;
  color: #898786;
  font-size: 0.9rem;
}

.NonConcertableLayoutOne_DescriptionPlus__3qNdt {
  background-color: #e0e0e0;
  border-radius: 5px;
  padding: 0.5rem 1rem;
  color: #898786;
  font-size: 0.9rem;
  margin-top: 1.5rem;
}
.NonConcertableLayoutOne_DescriptionPlus__3qNdt span {
  color: #3d9dc7;
  font-weight: 900;
}

.NonConcertableLayoutOne_CardContent__k2-IY {
  margin-top: 2rem;
  padding: 2rem 2.5em;
  background-color: #f3f2f0;
}

.NonConcertableLayoutOne_Emoji__3Iz6w {
  font-size: 1.8rem;
}

.NonConcertableLayoutOne_QuestionsHeader__3MCrT {
  color: #3b4578;
  margin-top: 0.7em;
  margin-bottom: 1.5rem;
}

.NonConcertableLayoutOne_QuestionTitle__24tqQ {
  margin-top: 0.5em;
  font-size: 1rem;
  display: flex;
  /* justify-content: center; */
  align-items: center;
}
.NonConcertableLayoutOne_QuestionTitle__24tqQ p {
  color: #2a2f39;
  margin-left: 0.4em;
}

.NonConcertableLayoutOne_CommentSection__3Sx0z {
  margin-top: 2rem;
  text-align: center;
}

.NonConcertableLayoutOne_Buttons__3ipFh {
  display: flex;
  margin: 2rem 0;
  justify-content: space-between;
}

.NonConcertableLayoutOne_NCSection__hkHEF {
  margin-top: 1.1em;
}
.NonConcertableLayoutOne_MapImage__allsY {
  margin-top: 0.85em;
  /* box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.2), 0px 4px 5px rgba(0, 0, 0, 0.12),
    0px 2px 4px rgba(0, 0, 0, 0.14); */
  /* border-radius: 10px; */
}

.NonConcertableLayoutOne_Notices__3LD0l {
  margin: 2em 0;
  font-size: 0.6rem;
}

/* Desktops */
@media screen and (min-width: 910px) {
  .NonConcertableLayoutOne_Card__34WnY {
    padding: 0;
    border-radius: 10px;
    width: 80%;
    height: 90%;
    max-width: 50rem;
    max-height: 40rem;
    outline: none;
    display: flex;
  }
  .NonConcertableLayoutOne_Card__34WnY::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  .NonConcertableLayoutOne_Card__34WnY {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }

  .NonConcertableLayoutOne_VideoPreview__1ctKJ {
    margin-top: 3rem;
  }

  .NonConcertableLayoutOne_LeftSection__3_qww {
    background-color: white;
    width: 50%;
    padding: 1em;
    padding-bottom: 3em;
  }

  .NonConcertableLayoutOne_RightSection__3gE_F {
    background-color: #f3f2f0;
    width: 50%;
    height: 100%;
    position: relative;
  }

  .NonConcertableLayoutOne_DescriptionPlus__3qNdt {
    margin-top: 0;
  }

  .NonConcertableLayoutOne_CardContent__k2-IY {
    height: 100%;
    margin-top: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
}

.ProfilePage_HeaderImageWrapper__3N0PV {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
}
.ProfilePage_HeaderImageWrapper__3N0PV img {
  width: 80%;
}

.ProfilePage_ProfilePage__12ILb h1 {
  margin: 1rem 2rem;
}
.ProfilePage_ProfilePage__12ILb h3 {
  margin: 1rem 0 1rem 2rem;
}
.ProfilePage_SubmitButton__2Qnf0 {
  height: 6rem;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 2rem;
}

.OvalButton_OvalButton__18ygo {
  display: flex;
  margin: 0 auto;
  padding: 20px 40px;
  border: 0;
  outline: 0;
  background: #ebecf0;
  border: 1px solid rgba(255, 255, 255, 0.7);
  box-sizing: border-box;
  box-shadow: 5px 5px 10px #a6abbd, -5px -5px 10px #fafbff;
  border-radius: 41px;
}

.OvalButton_OvalButton__18ygo:active {
  box-shadow: inset 1px 1px 2px #babecc, inset -1px -1px 2px #fff;
}

.Resultats_Main__3xRUy {
  padding: 0 1em;
  margin-bottom: 2em;
}

.Resultats_Title__2IHps {
  font-size: 0.9rem;
  margin-bottom: 2em;
}
.Resultats_Title__2IHps span {
  font-size: 1.5rem;
  font-weight: bold;
}
.Resultats_StatsIllustration__15VVp {
  margin-top: 2em;
}
.Resultats_StatsContent__dC0iw {
  display: flex;
  flex-direction: column;
  text-align: center;
}
.Resultats_StatsContent__dC0iw h3 {
  font-size: 1.4rem;
  font-weight: normal;
}
.Resultats_StatsContent__dC0iw p {
  font-size: 2rem;
  font-weight: bold;
}
.Resultats_Illustration__3_mKg {
  margin: 1em auto;
  width: 80%;
  max-width: 25em;
  display: flex;
  justify-content: center;
}

/* large tablets & laptops */
@media screen and (min-width: 768px) {
  .Resultats_Resultats__2GWDf {
    margin: 0 2em;
    margin-top: 1em;
  }
}
/* large tablets & laptops */
@media screen and (min-width: 1200px) {
  .Resultats_Resultats__2GWDf {
    margin: 0 10em;
  }
}

.PageOne_SectionTitle__3Xmps {
  text-align: center;
}
.PageOne_SectionTitle__3Xmps .PageOne_Emoji__pu9ul {
  font-size: 2.8em;
}
.PageOne_SectionTitle__3Xmps h3 {
  font-size: 1.5em;
}
.PageOne_SectionSubtitle__2RdjE {
  font-weight: bold;
  font-size: 0.8em;
  margin: 0.8em 2em;
}

.PageOne_EmojiText__1Eb_6 {
  display: flex;
  margin: 0 2em;
}
.PageOne_EmojiText__1Eb_6 .PageOne_Emoji__pu9ul {
  font-size: 1.2em;
}

.PageOne_EmojiText__1Eb_6 p {
  font-size: 0.9em;
  margin-left: 0.5em;
  margin-top: 0.3em;
}

.PageOne_ParticiperButton__2MgwD {
  text-align: center;
  margin: 1.5em 0;
}

.PageOne_Illustration__2cKV9 {
  width: 275px;
  height: 275px;
  box-shadow: 2px 2px 6px #a6abbd, -2px -2px 6px #fafbff;
  border-radius: 8px;
  background-repeat: no-repeat;
  background-size: cover;
  margin: 2em auto;
}

/* Caroussel */
.PageOne_CarouselSection__73lXS {
  margin: 30px 0;
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
}
.PageOne_CarouselSection__73lXS::-webkit-scrollbar {
  display: none; /* Safari & Chrome*/
}

.PageOne_CarouselItem__3WGqR {
  flex: 0 0 auto;
  margin-bottom: 5px;
  margin-top: 5px;
  margin-right: 30px;
  width: 275px;
  height: 275px;
  box-shadow: 2px 2px 6px #a6abbd, -2px -2px 6px #fafbff;
  border-radius: 8px;
  background-repeat: no-repeat;
  background-size: cover;
}
.PageOne_CarouselItem__3WGqR:first-child {
  margin-left: 40px;
}
.PageOne_CourselItem__2jbGu:last-child {
  margin-right: 20px;
}

.PageOne_GrandesEtapes__2Hzpo {
  display: flex;
  justify-content: center;
}
.PageOne_GrandesEtapes__2Hzpo img {
  width: 80%;
  max-width: 33em;
  margin-bottom: -3em;
}
.PageOne_HeroImage__2jpQO {
  width: 100%;
  margin-bottom: 1.5em;
  z-index: -1;
}
.PageOne_HeroImage__2jpQO img {
  width: 100%;
}

/* large tablets & laptops */
@media screen and (min-width: 768px) {
  .PageOne_HeroImage__2jpQO {
    width: 40em;
    margin: 0 auto;
    margin-bottom: 3em;
  }
  .PageOne_HeroImage__2jpQO img {
    border-radius: 8px;
    box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.2), 0px 4px 5px rgba(0, 0, 0, 0.12),
      0px 2px 4px rgba(0, 0, 0, 0.14);
  }
  .PageOne_Section__3S70C {
    margin: 0 5.5em;
  }
  .PageOne_SectionTitle__3Xmps {
    display: flex;
    align-items: flex-end;
  }
  .PageOne_SectionSubtitle__2RdjE {
    font-weight: bold;
    font-size: 0.9em;
    margin: 0.8em 0;
  }
  .PageOne_ContactForm__XJxkX {
    margin: 0 auto;
    max-width: 40em;
  }
}

/* large tablets & laptops */
@media screen and (min-width: 1200px) {
  .PageOne_HeroImage__2jpQO {
    width: 50em;
    margin: 0 auto;
    margin-bottom: 4em;
  }
  .PageOne_Section__3S70C {
    margin: 0 20em;
  }
  .PageOne_Illustration__2cKV9 {
    width: 25em;
    height: 25em;
  }
}

.ContactForm_ContactForm__2Av7l {
  margin: 1.5em 0.3em;
}
.ContactForm_ContactFormTitle__3WKDT {
  font-weight: bold;
  font-size: 1rem;
  text-align: center;
}
.ContactForm_Emoji__1fNX7 {
  font-size: 1.5rem;
}
.ContactForm_ContactFormFields__3qrqg {
  margin: 2em;
  display: flex;
  flex-direction: column;
}
.ContactForm_Button__4hmuU {
  text-align: end;
}
.ContactForm_Success__5qwdv {
  margin-left: 0.2em;
  margin-bottom: 1em;
  color: green;
  font-size: 0.8rem;
}
.ContactForm_Error__3kSmB {
  margin-bottom: 1em;
  margin-left: 0.2em;
  color: red;
  font-size: 0.8rem;
}

.Separator_Separator__BOxKh {
  margin: 0 auto;
  height: 4px;
  width: 90%;
  max-width: 60em;
  background: #ebecf0;
  border: 1px solid rgba(255, 255, 255, 0.4);
  box-sizing: border-box;
  box-shadow: inset 1px 3px 5px #a6abbd, inset 7px 4px 14px #fafbff;
  border-radius: 4px;
}

/* .Content {
  margin-top: 56px;
} */

/* Small tablets */

/* Large tablets & laptops */
@media (min-width: 960px) {
  .Layout_Content__30ZPo {
    margin-top: 0px;
  }
}

.Toolbar_Toolbar__zYjus {
  height: 57px;
  width: 100%;
  top: 0;
  left: 0;
  position: fixed;
  background-color: #ebecf0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  box-sizing: border-box;
  z-index: 90;
  box-shadow: 5px 5px 10px #a6abbd, -5px -5px 10px #fafbff;
}

/* .Logo {
  height: 60%;
} */
.Toolbar_Logo__1CBPt h3 {
  text-transform: uppercase;
  font-size: 1.8rem;
  color: #5b5855;
  font-weight: bold;
  cursor: pointer;
}

@media (max-width: 619px) {
  .Toolbar_DesktopOnly__1Xxeb {
    display: none;
  }
}

/* Small tablets */
@media (min-width: 620px) {
  .Toolbar_Toolbar__zYjus {
    /* box-shadow: none; */
    height: 70px;
    padding: 0 40px;
    /* position: relative; */
    background-color: white;
  }
  .Toolbar_Logo__1CBPt h3 {
    font-size: 1.8rem;
  }
}

/* Large Tablets & laptops */
@media screen and (min-width: 960px) {
  .Toolbar_Toolbar__zYjus {
    /* height: 100px; */
    padding: 10px 80px;
  }
  .Toolbar_Logo__1CBPt h3 {
    font-size: 2rem;
  }
}

/* Large Tablets & laptops */
@media screen and (min-width: 1200px) {
  .Toolbar_Toolbar__zYjus {
    padding: 10px 100px;
  }
}

.SideDrawer_SideDrawer__EesE_ {
  position: fixed;
  width: 280px;
  max-width: 60%;
  height: 100%;
  right: 0;
  top: 0;
  z-index: 200;
  background-color: #ebecf0;
  padding: 32px 16px;
  box-sizing: border-box;
  -webkit-transition: -webkit-transform 0.3s ease-out;
  transition: -webkit-transform 0.3s ease-out;
  transition: transform 0.3s ease-out;
  transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out;
}

@media (min-width: 620px) {
  .SideDrawer_SideDrawer__EesE_ {
    display: none;
  }
}

.SideDrawer_Open__1LrKJ {
  -webkit-transform: translateX(0);
          transform: translateX(0);
}

.SideDrawer_Close__juiyj {
  -webkit-transform: translateX(100%);
          transform: translateX(100%);
}

.SideDrawer_Logo__gM6uE {
  height: 11%;
  margin-bottom: 32px;
}

.NavigationItems_NavigationItems__2nf3q {
  padding: 0;
  margin: 0;
  list-style-type: none;
  display: flex;
  flex-flow: column;
  align-items: center;
  height: 100%;
}

/* Small tablets */
@media screen and (min-width: 620px) {
  .NavigationItems_NavigationItems__2nf3q {
    flex-flow: row;
    width: 50vw;
  }

  .NavigationItems_Participer__32q-X {
    margin-left: 25px;
  }
}

/* Large tablets & laptops */
@media (min-width: 960px) {
  .NavigationItems_NavigationItems__2nf3q {
    width: 45vw;
  }
}

.NavigationItem_NavigationItem__3Gknc {
  margin: 10px 0;
  box-sizing: border-box;
  display: block;
  width: 100%;
}

.NavigationItem_NavigationItem__3Gknc a {
  color: #2a2f39;
  padding: 10px 20px;
  text-decoration: none;
  width: 100%;
  box-sizing: border-box;
  display: block;
  background: #ebecf0;
  border: 1px solid rgba(255, 255, 255, 0.4);
  box-sizing: border-box;
  box-shadow: 2px 2px 6px #a6abbd, -2px -2px 6px #fafbff;
  border-radius: 7px;
  text-align: center;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}

.NavigationItem_NavigationItem__3Gknc a:hover,
.NavigationItem_NavigationItem__3Gknc a:active,
.NavigationItem_NavigationItem__3Gknc a.NavigationItem_active__yMdO_ {
  color: #e6404d;
  font-weight: bold;
  box-shadow: inset 5px 5px 10px #a6abbd, inset -5px -5px 10px #fafbff;
}

/* Small tablets */
@media (min-width: 620px) {
  .NavigationItem_NavigationItem__3Gknc {
    margin: 10px 10px;
    padding: 10 30px;
  }
  .NavigationItem_NavigationItem__3Gknc a {
    border: none;
    box-shadow: none;
    padding: 10px 0;
  }
  .NavigationItem_NavigationItem__3Gknc a:hover,
  .NavigationItem_NavigationItem__3Gknc a:active,
  .NavigationItem_NavigationItem__3Gknc a.NavigationItem_active__yMdO_ {
    box-shadow: none;
    font-weight: bold;
    color: #e6404d;
  }
}

.LongButton_LongButton__3QtgZ {
  font-family: inherit;
  font-size: 0.9em;
  font-weight: bold;
  padding: 5px 15px;
  outline: none;
  background: #ebecf0;
  border: 1px solid rgba(255, 255, 255, 0.4);
  box-sizing: border-box;
  box-shadow: 2px 2px 6px #a6abbd, -2px -2px 6px #fafbff;
  border-radius: 4px;
  cursor: pointer;
}
.LongButton_LongButton__3QtgZ:active {
  box-shadow: inset 1px 1px 2px #babecc, inset -1px -1px 2px #fff;
}

