.CalendarModal {
  background: #ffffff;
  border-radius: 18px;
  height: 30rem;
  overflow-y: scroll;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.CalendarModal::-webkit-scrollbar {
  display: none;
}

.StepTitle {
  background-color: #3b4578;
  color: white;
  border-radius: 18px 18px 0 0;
  display: flex;
  padding: 1rem;
  font-size: 1.18rem;
  justify-content: center;
  align-items: center;
  position: sticky;
}
.TitleEmoji {
  font-size: 3rem;
  margin-right: 1rem;
  margin-top: -0.5rem;
}
.Substeps {
  padding: 1rem 1rem;
}
.Substep {
  color: #292f33;
  font-size: 1rem;
  margin-bottom: 2rem;
}
.Description {
  display: flex;
  margin-bottom: 0.3rem;
}
.Emoji {
  font-size: 2rem;
  margin-right: 0.5rem;
}

/* large tablets & laptops */
@media screen and (min-width: 600px) {
}

/* large tablets & laptops */
@media screen and (min-width: 768px) {
}

/* large tablets & laptops */
@media screen and (min-width: 1200px) {
}

/* Very large screens */
@media screen and (min-width: 1500px) {
}
