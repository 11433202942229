/* .Travaux {
  overflow-x: hidden;
} */
.SubHeader {
  background-color: #ee9834;
  padding: 0;
  margin-top: -0.1rem;
  padding-top: 2rem;
}
.HomeLink {
  color: white;
  display: flex;
  justify-content: center;
}

.HomeLink a {
  color: white;
  font-size: 0.9rem;
  margin-left: 0.4rem;
  text-decoration: underline;
}
.Main {
  padding: 0 0.2em;
  margin-bottom: 5em;
}
.CardListTitle {
  margin-top: 3rem;
  margin-bottom: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.CardListTitle p {
  font-size: 1.2rem;
  text-align: center;
  color: #828489;
  margin-bottom: 1rem;
}
.CardListTitle span {
  font-size: 1.2rem;
  font-weight: bold;
  color: #292f33;
}
.Travaux {
  /* margin-bottom: 4em; */
}

/* large tablets & laptops */
@media screen and (min-width: 768px) {
  .SubHeader {
    padding: 0 4rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .CardListTitle {
    flex-direction: row;
    justify-content: space-between;
  }
  .CardListTitle p {
    font-size: 1.4rem;
  }
  .CardListTitle span {
    font-size: 1.4rem;
  }
  .Travaux {
    margin: 0 4rem;
    margin-top: 1em;
    /* margin-bottom: 4em; */
  }
  .MainCards {
    display: flex;
    position: relative;
    min-height: 20em;
  }
  /* .NoCard {
    background-color: #f3f2f08a;
    border-radius: 14px;
    margin-left: 3em;
    margin-bottom: 2em;
    position: absolute;
    height: 30em;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    width: 20em;
  } */
}

/* large tablets & laptops */
@media screen and (min-width: 1200px) {
  .SubHeader {
    padding: 0 11rem;
  }
  .Travaux {
    margin: 0 11em;
    /* margin-bottom: 4em; */
  }
}
