.Card {
  max-width: 15rem;
  min-width: 15rem;
  max-height: 10rem;
  padding: 1rem;
  margin-bottom: 2rem;
  margin-right: 2rem;
  background: #ffffff;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25), 0px 4px 4px rgba(0, 0, 0, 0.1);
  border-radius: 18px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
}
.Emoji {
  font-size: 2rem;
  margin: auto;
}
.Card p {
  margin-top: 0.5rem;
  font-size: 1rem;
}
.SelectedCard {
  background: #3b4578;
  border-radius: 18px;
  color: white;
  max-width: 15rem;
  max-height: 10rem;
  padding: 1rem;
  margin-bottom: 2rem;
  margin-right: 2rem;
  cursor: pointer;
  display: flex;
  flex-direction: column;
}
.SelectedCard p {
  margin-top: 0.5rem;
  font-size: 1rem;
}

/* large tablets & laptops */
@media screen and (min-width: 600px) {
}

/* large tablets & laptops */
@media screen and (min-width: 768px) {
}

/* large tablets & laptops */
@media screen and (min-width: 1200px) {
}

/* Very large screens */
@media screen and (min-width: 1500px) {
}
